import React, { useState, useEffect, useRef } from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import HeaderLogo from "../../components/headerLogo/headerLogo"
import HeaderBack from "../../components/headerBack/headerBack"
import HeaderCart from "../../components/headerCart/headerCart"

import ProductV2 from "../../components/product/productV2"

function ProductShopify() {

  const shopifyProductId = '6128078127271'
  const base64ProductId = Buffer.from('gid://shopify/Product/' + shopifyProductId).toString('base64')

  const sizes = {
    "Length": [
      "27 1/2 / 70"
    ]
  }

  return (
    <Layout>
      <SEO title="Shop - Baggage Tag Keyring" />

      <ProductV2 productId={base64ProductId} sizes={sizes} />
    </Layout>
  )

} export default ProductShopify